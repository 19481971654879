"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkArray = exports.createArrayGroup = void 0;
var createArrayGroup = function (array, NumberGroups) {
    console.log({ array: array });
    var perGroup = Math.ceil(array.length / Number(NumberGroups));
    return new Array(NumberGroups).fill("").map(function (_, i) {
        return array.slice(i * perGroup, (i + 1) * perGroup);
    });
};
exports.createArrayGroup = createArrayGroup;
var chunkArray = function (array, chunkSize) {
    var chunk = [];
    for (var i = 0; i < array.length; i += chunkSize) {
        chunk = __spreadArray(__spreadArray([], chunk, true), [array.slice(i, i + chunkSize)], false);
        // do whatever
    }
    return chunk;
};
exports.chunkArray = chunkArray;
