"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SUCCESS_STORY = exports.GET_DEPENDENCY_FINANCIAL = exports.GET_TABULAR_SORTING_ORDER = exports.GET_FINANCIAL_TABULAR_OPTIONS = exports.GET_FINANCIAL_INFO_BY_CATEGORY = exports.GET_FINANCIAL_INFO = exports.GET_USER_TYPE = exports.GET_DATA_TYPE = exports.GET_FINANCIAL_CATEGORY = exports.FRAGMENT_INFO = exports.FRAGMENT_CATEGORY = void 0;
var client_1 = require("@apollo/client");
exports.FRAGMENT_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    fragment ChildFields on FinancialCategory {\n        id\n        name\n        child_count\n\n        parent {\n            id\n        }\n    }\n\n    fragment ChildRecursive on FinancialCategory {\n        child {\n            ...ChildFields\n            child {\n                ...ChildFields\n                child {\n                    ...ChildFields\n                }\n            }\n        }\n    }\n"], ["\n    fragment ChildFields on FinancialCategory {\n        id\n        name\n        child_count\n\n        parent {\n            id\n        }\n    }\n\n    fragment ChildRecursive on FinancialCategory {\n        child {\n            ...ChildFields\n            child {\n                ...ChildFields\n                child {\n                    ...ChildFields\n                }\n            }\n        }\n    }\n"])));
var GET_FINANCIAL_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query getFinancialCategoryByPublished($organization_id: ID!) {\n        getFinancialCategoryByPublished(\n            published: null\n            organization_id: $organization_id\n        ) {\n            ...ChildFields\n            ...ChildRecursive\n        }\n    }\n    ", "\n"], ["\n    query getFinancialCategoryByPublished($organization_id: ID!) {\n        getFinancialCategoryByPublished(\n            published: null\n            organization_id: $organization_id\n        ) {\n            ...ChildFields\n            ...ChildRecursive\n        }\n    }\n    ", "\n"])), exports.FRAGMENT_CATEGORY);
exports.GET_FINANCIAL_CATEGORY = GET_FINANCIAL_CATEGORY;
exports.FRAGMENT_INFO = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    fragment InfoFields on FinancialInfo {\n        id\n        name\n        child_count\n        financial_category_id\n        data_type_id\n        order_by\n        dependent_id\n        code\n        editable\n        calculation\n        parent {\n            id\n        }\n        user_types {\n            id\n            name\n        }\n        financial_category {\n            id\n            name\n        }\n        data_type {\n            id\n            name\n        }\n        options {\n            id\n            name\n        }\n        parent {\n            id\n        }\n        color_code\n        key\n    }\n\n    fragment InfoRecursive on FinancialInfo {\n        child {\n            ...InfoFields\n        }\n    }\n"], ["\n    fragment InfoFields on FinancialInfo {\n        id\n        name\n        child_count\n        financial_category_id\n        data_type_id\n        order_by\n        dependent_id\n        code\n        editable\n        calculation\n        parent {\n            id\n        }\n        user_types {\n            id\n            name\n        }\n        financial_category {\n            id\n            name\n        }\n        data_type {\n            id\n            name\n        }\n        options {\n            id\n            name\n        }\n        parent {\n            id\n        }\n        color_code\n        key\n    }\n\n    fragment InfoRecursive on FinancialInfo {\n        child {\n            ...InfoFields\n        }\n    }\n"])));
var GET_FINANCIAL_INFO = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query getFinancialInfo($organization_id: ID!) {\n        getFinancialInfo(organization_id: $organization_id) {\n            ...InfoFields\n            ...InfoRecursive\n        }\n    }\n    ", "\n"], ["\n    query getFinancialInfo($organization_id: ID!) {\n        getFinancialInfo(organization_id: $organization_id) {\n            ...InfoFields\n            ...InfoRecursive\n        }\n    }\n    ", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_INFO = GET_FINANCIAL_INFO;
var GET_FINANCIAL_INFO_BY_CATEGORY = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    query getFinancialInfoByCategory(\n        $financial_category_id: ID!\n        $organization_id: ID!\n    ) {\n        getFinancialInfoByCategory(\n            financial_category_id: $financial_category_id\n            organization_id: $organization_id\n        ) {\n            ...InfoFields\n            ...InfoRecursive\n        }\n    }\n    ", "\n"], ["\n    query getFinancialInfoByCategory(\n        $financial_category_id: ID!\n        $organization_id: ID!\n    ) {\n        getFinancialInfoByCategory(\n            financial_category_id: $financial_category_id\n            organization_id: $organization_id\n        ) {\n            ...InfoFields\n            ...InfoRecursive\n        }\n    }\n    ", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_INFO_BY_CATEGORY = GET_FINANCIAL_INFO_BY_CATEGORY;
var GET_DATA_TYPE = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    query getDataType {\n        getDataType {\n            id\n            name\n        }\n    }\n"], ["\n    query getDataType {\n        getDataType {\n            id\n            name\n        }\n    }\n"])));
exports.GET_DATA_TYPE = GET_DATA_TYPE;
var GET_USER_TYPE = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    query getUserType {\n        getUserType {\n            id\n            name\n        }\n    }\n"], ["\n    query getUserType {\n        getUserType {\n            id\n            name\n        }\n    }\n"])));
exports.GET_USER_TYPE = GET_USER_TYPE;
var GET_FINANCIAL_TABULAR_OPTIONS = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    query getFinancialTabularById($id: ID!) {\n        getFinancialTabularById(id: $id) {\n            ...InfoFields\n            ...InfoRecursive\n        }\n    }\n    ", "\n"], ["\n    query getFinancialTabularById($id: ID!) {\n        getFinancialTabularById(id: $id) {\n            ...InfoFields\n            ...InfoRecursive\n        }\n    }\n    ", "\n"])), exports.FRAGMENT_INFO);
exports.GET_FINANCIAL_TABULAR_OPTIONS = GET_FINANCIAL_TABULAR_OPTIONS;
var GET_TABULAR_SORTING_ORDER = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    query getTabularSortingOrder($parent_id: ID!, $organization_id: ID!) {\n        getTabularSortingOrder(\n            parent_id: $parent_id\n            organization_id: $organization_id\n        ) {\n            id\n            name\n            order_by\n            key\n            color_code\n            code\n            editable\n            calculation\n            dependent_id\n        }\n    }\n"], ["\n    query getTabularSortingOrder($parent_id: ID!, $organization_id: ID!) {\n        getTabularSortingOrder(\n            parent_id: $parent_id\n            organization_id: $organization_id\n        ) {\n            id\n            name\n            order_by\n            key\n            color_code\n            code\n            editable\n            calculation\n            dependent_id\n        }\n    }\n"])));
exports.GET_TABULAR_SORTING_ORDER = GET_TABULAR_SORTING_ORDER;
var GET_DEPENDENCY_FINANCIAL = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    query getDependencyFinancialInfo(\n        $financial_category_id: ID!\n        $organization_id: ID!\n    ) {\n        getDependencyFinancialInfo(\n            financial_category_id: $financial_category_id\n            organization_id: $organization_id\n        ) {\n            id\n            name\n            order_by\n        }\n    }\n"], ["\n    query getDependencyFinancialInfo(\n        $financial_category_id: ID!\n        $organization_id: ID!\n    ) {\n        getDependencyFinancialInfo(\n            financial_category_id: $financial_category_id\n            organization_id: $organization_id\n        ) {\n            id\n            name\n            order_by\n        }\n    }\n"])));
exports.GET_DEPENDENCY_FINANCIAL = GET_DEPENDENCY_FINANCIAL;
var GET_SUCCESS_STORY = (0, client_1.gql)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    query getSuccessStory($organization_id: ID!) {\n        getSuccessStory(organization_id: $organization_id) {\n            id\n            title\n            description\n            sorting\n            url\n            web_url\n        }\n    }\n"], ["\n    query getSuccessStory($organization_id: ID!) {\n        getSuccessStory(organization_id: $organization_id) {\n            id\n            title\n            description\n            sorting\n            url\n            web_url\n        }\n    }\n"])));
exports.GET_SUCCESS_STORY = GET_SUCCESS_STORY;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
