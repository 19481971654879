"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function errorParse(error, custom) {
    if (custom === void 0) { custom = false; }
    if (custom) {
        return {
            status: "error",
            error: {
                code: 404,
                message: error,
            },
        };
    }
    else if (error.response) {
        var err = error.response.data.error, message = err.message, code = err.code;
        return {
            status: "error",
            error: {
                code: code,
                message: message,
                field: err.field,
            },
        };
    }
    return {
        status: "error",
        error: {
            code: 404,
            message: "please check api point or network",
        },
    };
}
exports.default = errorParse;
