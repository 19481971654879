"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_CONFLICT_CATEGORY = exports.GET_CONFLICT = void 0;
var client_1 = require("@apollo/client");
var GET_CONFLICT_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getConflictCategoryByOrganization($organization_id: ID!) {\n    getConflictCategoryByOrganization(organization_id: $organization_id) {\n      id\n      name\n      category_icon\n      category_icon_web\n      sorting\n      organization_id\n      conflict {\n        id\n        title\n        description\n        sorting\n        conflict_category_id\n        conflict_media {\n          id\n          thumbnail_link\n          video_link\n          web_thumbnail_link\n          web_video_link\n        }\n      }\n    }\n  }\n"], ["\n  query getConflictCategoryByOrganization($organization_id: ID!) {\n    getConflictCategoryByOrganization(organization_id: $organization_id) {\n      id\n      name\n      category_icon\n      category_icon_web\n      sorting\n      organization_id\n      conflict {\n        id\n        title\n        description\n        sorting\n        conflict_category_id\n        conflict_media {\n          id\n          thumbnail_link\n          video_link\n          web_thumbnail_link\n          web_video_link\n        }\n      }\n    }\n  }\n"])));
exports.GET_CONFLICT_CATEGORY = GET_CONFLICT_CATEGORY;
var GET_CONFLICT = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getConflictByOrganization($conflict_category_id: ID!, $organization_id: ID!) {\n    getConflictByOrganization(\n      conflict_category_id: $conflict_category_id\n      organization_id: $organization_id\n    ) {\n      id\n      sorting\n      title\n      description\n      conflict_media {\n        thumbnail_link\n        video_link\n      }\n      conflict_category_id\n    }\n  }\n"], ["\n  query getConflictByOrganization($conflict_category_id: ID!, $organization_id: ID!) {\n    getConflictByOrganization(\n      conflict_category_id: $conflict_category_id\n      organization_id: $organization_id\n    ) {\n      id\n      sorting\n      title\n      description\n      conflict_media {\n        thumbnail_link\n        video_link\n      }\n      conflict_category_id\n    }\n  }\n"])));
exports.GET_CONFLICT = GET_CONFLICT;
var templateObject_1, templateObject_2;
