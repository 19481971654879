"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
// import {isJsonWithAvailableLang} from "../../../../lib/general";
// import SvgIcon from "../../../../theme/icons";
var CountryList = function (props) {
    var header = ["name", "code"], _a = (0, react_i18next_1.useTranslation)(), t = _a.t, i18n = _a.i18n, currentLang = i18n.language;
    var _b = (0, react_1.useState)([]), tableFields = _b[0], setTableFields = _b[1], _c = (0, react_1.useState)(null), click = _c[0], setClick = _c[1];
    (0, react_1.useEffect)(function () {
        setTableFields(props.list);
    }, [props.list]);
    var renderTableHeader = function () {
        return header.map(function (item, key) { return (react_1.default.createElement("th", { className: "cell table-header", key: key, style: {
                // backgroundColor: "#009CFF",
                color: "white",
            } }, t(item))); });
    };
    var column = function (l, i) { return (react_1.default.createElement("tr", { key: l.id },
        react_1.default.createElement("td", { className: "cell" }, l.name),
        react_1.default.createElement("td", { className: "cell" }, l.code))); };
    var renderBody = function () { return (react_1.default.createElement(react_1.default.Fragment, null, tableFields === null || tableFields === void 0 ? void 0 : tableFields.map(function (l, i) { return (react_1.default.createElement(react_1.Fragment, { key: i }, column(l, i))); }))); };
    return (react_1.default.createElement("div", { className: "tableListContainer" },
        react_1.default.createElement("div", null,
            react_1.default.createElement("table", { className: "tableView" },
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null, renderTableHeader())),
                react_1.default.createElement("tbody", null, renderBody())))));
};
exports.default = CountryList;
