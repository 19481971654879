"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDependencyFinancialInfo = exports.getTabularSortingOrder = exports.getUserType = exports.getDataType = void 0;
var general_1 = require("../../../../lib/general");
var Queries_1 = require("../Graphql/Queries");
var getDataType = function (client) {
    return client
        .query({
        query: Queries_1.GET_DATA_TYPE,
        fetchPolicy: "no-cache",
    })
        .then(function (data) {
        var finalList = [];
        var list = data === null || data === void 0 ? void 0 : data.data.getDataType;
        if (list.length > 0) {
            finalList = list.map(function (row) { return ({
                value: row.id,
                label: row.name,
            }); });
        }
        return finalList;
    })
        .catch(function () { return []; });
}, getUserType = function (client) {
    return client
        .query({
        query: Queries_1.GET_USER_TYPE,
        fetchPolicy: "no-cache",
    })
        .then(function (data) {
        var finalList = [];
        var list = data === null || data === void 0 ? void 0 : data.data.getUserType;
        if (list.length > 0) {
            finalList = list.map(function (row) { return ({
                value: row.id,
                label: row.name,
            }); });
        }
        return finalList;
    })
        .catch(function () { return []; });
}, getTabularSortingOrder = function (client, parentId, currentLang, t, organization_id) {
    return client
        .query({
        query: Queries_1.GET_TABULAR_SORTING_ORDER,
        fetchPolicy: "no-cache",
        variables: {
            organization_id: organization_id,
            parent_id: parentId,
        },
    })
        .then(function (data) {
        var finalList = [];
        var list = data === null || data === void 0 ? void 0 : data.data.getTabularSortingOrder;
        if (list.length > 0) {
            finalList = list.map(function (row) { return ({
                value: row.order_by,
                label: "before ".concat((0, general_1.isJsonWithAvailableLang)(row.name, currentLang, t)),
                name: row.name,
                id: row.order_by,
            }); });
        }
        return finalList;
    })
        .catch(function () { return []; });
}, getDependencyFinancialInfo = function (client, financial_category_id, currentLang, t, organization_id) {
    return client
        .query({
        query: Queries_1.GET_DEPENDENCY_FINANCIAL,
        fetchPolicy: "no-cache",
        variables: {
            organization_id: organization_id,
            financial_category_id: financial_category_id,
        },
    })
        .then(function (data) {
        var list = data === null || data === void 0 ? void 0 : data.data.getDependencyFinancialInfo;
        var tmp = [];
        if (list.length > 0) {
            list.map(function (l) {
                tmp.push({
                    value: l.id,
                    label: (0, general_1.isJsonWithAvailableLang)(l.name, currentLang, t),
                });
            });
        }
        return tmp;
    })
        .catch(function (err) {
        return [];
    });
};
exports.getDataType = getDataType;
exports.getUserType = getUserType;
exports.getTabularSortingOrder = getTabularSortingOrder;
exports.getDependencyFinancialInfo = getDependencyFinancialInfo;
