"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateSuccessStory = exports.validateHierarchyForm = exports.validateHoushold = exports.ValidateForm = void 0;
var ValidateForm = function (paylaod) {
    if (paylaod.type === "income" || paylaod.type === "expense") {
        if (paylaod.name === "") {
            return {
                message: "required",
                field: "name",
                status: "error",
            };
        }
        else if (paylaod.category_id === null) {
            return {
                message: "required",
                field: "category",
                status: "error",
            };
        }
        else {
            return {
                message: "success",
                field: "",
                status: "success",
            };
        }
    }
    else {
        if (paylaod.name === "") {
            return {
                message: "required",
                field: "name",
                status: "error",
            };
        }
        else {
            return {
                message: "success",
                field: "",
                status: "success",
            };
        }
    }
};
exports.ValidateForm = ValidateForm;
var validateHoushold = function (payload, getCurrentLang) {
    if (payload.name === "") {
        // if(JSON.parse(payload.name)[getCurrentLang] === "")
        return {
            message: "required",
            field: "name",
            status: "error",
        };
    }
    else if (JSON.parse(payload.name)[getCurrentLang] === "") {
        return {
            message: "required",
            field: "name",
            status: "error",
        };
    }
    else if (payload.data_type_id === "") {
        return {
            message: "required",
            field: "data_type_id",
            status: "error",
        };
    }
    else if (payload.user_types === null || payload.user_types === "") {
        if (payload.user_types.length > 0) {
            return {
                message: "required",
                field: "user_types",
                status: "error",
            };
        }
        else {
            return {
                message: "required",
                field: "user_types",
                status: "error",
            };
        }
    }
    else {
        return {
            message: "success",
            field: "",
            status: "success",
        };
    }
};
exports.validateHoushold = validateHoushold;
var validateHierarchyForm = function (payload, getCurrentLang) {
    if (JSON.parse(payload.name)[getCurrentLang] === "") {
        return {
            message: "required",
            field: "name",
            status: "error",
        };
    }
    else if (!payload.data_type_id) {
        return {
            message: "required",
            field: "data_type",
            status: "error",
        };
    }
    else if (payload.user_types.length === 0) {
        return {
            message: "required",
            field: "user_types",
            status: "error",
        };
    }
    else {
        return {
            message: "success",
            field: "",
            status: "success",
        };
    }
};
exports.validateHierarchyForm = validateHierarchyForm;
var validateSuccessStory = function (payload, getCurrentLang) {
    if (JSON.parse(payload.title)[getCurrentLang] === "") {
        return {
            message: "required",
            field: "title",
            status: "error",
        };
    }
    else if (!payload.video) {
        return {
            message: "required",
            field: "video",
            status: "error",
        };
    }
    else {
        return {
            message: "success",
            field: "",
            status: "success",
        };
    }
};
exports.validateSuccessStory = validateSuccessStory;
