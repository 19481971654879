"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_CONFLICT = exports.DELETE_CONFLICT = exports.UPSERT_CONFLICT_CATEGORY = exports.DELETE_CONFLICT_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var DELETE_CONFLICT_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation deleteConflictCategory($id: ID!, $organization_id: ID!) {\n    deleteConflictCategory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteConflictCategory($id: ID!, $organization_id: ID!) {\n    deleteConflictCategory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_CONFLICT_CATEGORY = DELETE_CONFLICT_CATEGORY;
var UPSERT_CONFLICT_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation bulkUpsertConflictCategory($input: [addCategoryPayload!]!) {\n    bulkUpsertConflictCategory(input: $input)\n  }\n"], ["\n  mutation bulkUpsertConflictCategory($input: [addCategoryPayload!]!) {\n    bulkUpsertConflictCategory(input: $input)\n  }\n"])));
exports.UPSERT_CONFLICT_CATEGORY = UPSERT_CONFLICT_CATEGORY;
var DELETE_CONFLICT = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation deleteConflict(\n    $organization_id: ID!\n    $conflict_category_id: ID!\n    $id: ID!\n  ) {\n    deleteConflict(\n      id: $id\n      organization_id: $organization_id\n      conflict_category_id: $conflict_category_id\n    )\n  }\n"], ["\n  mutation deleteConflict(\n    $organization_id: ID!\n    $conflict_category_id: ID!\n    $id: ID!\n  ) {\n    deleteConflict(\n      id: $id\n      organization_id: $organization_id\n      conflict_category_id: $conflict_category_id\n    )\n  }\n"])));
exports.DELETE_CONFLICT = DELETE_CONFLICT;
var UPSERT_CONFLICT = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation upsertConflict(\n    $conflict_id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $conflict_category_id: ID!\n    $conflict_media: MediaInput!\n    $organization_id: ID!\n  ) {\n    upsertConflict(\n      conflict_id: $conflict_id\n      title: $title\n      description: $description\n      sorting: $sorting\n      conflict_category_id: $conflict_category_id\n      conflict_media: $conflict_media\n      organization_id: $organization_id\n    )\n  }\n"], ["\n  mutation upsertConflict(\n    $conflict_id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $conflict_category_id: ID!\n    $conflict_media: MediaInput!\n    $organization_id: ID!\n  ) {\n    upsertConflict(\n      conflict_id: $conflict_id\n      title: $title\n      description: $description\n      sorting: $sorting\n      conflict_category_id: $conflict_category_id\n      conflict_media: $conflict_media\n      organization_id: $organization_id\n    )\n  }\n"])));
exports.UPSERT_CONFLICT = UPSERT_CONFLICT;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
